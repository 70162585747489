<template>
  <div>
    <div class="container">
      <p>{{ displayMessage }}</p>
      <img v-if="isLoading" src="../assets/img/loader.gif" height="60" width="60">
      <alert-error v-if="error">
        <template v-slot:alert-error>
          {{msgError}}
        </template>
      </alert-error>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TrayFinishInstall',
  mounted() {
    document.title = 'Finalizando a instalação da loja da Tray'

    let code = this.$route.query.code
    let apiAddress = this.$route.query.api_address
    let store = this.$route.query.store

    if(!code || !apiAddress || !store) {
      this.message = 'Houve um erro ao instalar sua loja da Tray.'
      this.loading = false
      return;
    }
    this.saveTokenResponse(code, apiAddress, store)
  },
  data: function () {
    return {
      payload: null,
      message: 'Verificando sua loja.',
      loading: true,
      error: false,
      msgError: ""
    }
  },
  computed: {
    displayMessage: function () {
      return this.message
    },
    isLoading: function () {
      return this.loading;
    }
  },
  methods: {
    saveTokenResponse(code, apiAddress, store) {
      this.loading = true

      this.$http.get(
          'tray/install/finish',
          {
            params: {code: code, 'api_address': apiAddress, store: store}
          }
      ).then((response) => {
        let data = response.data

        if (undefined === data.authorization) {
          this.loading = false
          this.message = 'Houve um erro ao preparar a sua loja para instalar o plugin do TikTok.'
          return
        }

        this.$cookies.set('Authorization', data.authorization, '1d',
            null, null, true, 'None');

        if (false === data.tiktok) {
          this.message = 'Estamos prontos para instalar o plugin do TikTok.'
          this.$http.get('tiktok/business/tiktok-auth-url', { headers: {'Authorization': data.authorization} })
                  .then(res => {
                    window.open(res.data.bc_url, "tiktoktray");
                  }).catch(error => {
                  this.msgError = error.response.data.message;
                  this.error = true;
          });
          return
        }

        if (false === data.wizard) {
          this.message = 'Estamos prontos para configurar o plugin do TikTok.'
          this.$router.replace('/wizard#pixel')
          return
        }

        this.$cookies.set('blockedAccount', data.block, '1d',
                null, null, true, 'None');

        this.message = 'Iniciando o plugin do TikTok.'
        this.$router.replace('/pagina-inicial')
      }).catch(error => {
        this.loading = false;
        let msg =  'Houve um erro ao preparar a sua loja para instalar o plugin do TikTok. ' + error.response.data.message;
        this.message  = msg;
        this.msgError = msg;
        this.error = true;
      });
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
div.container {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 500px;
}
</style>
